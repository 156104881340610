export default {
    set(key, value) {
        localStorage.setItem(key, value);
    },

    get(key) {
        return localStorage.getItem(key);
    },

    setLang(value) {
        localStorage.setItem('ez_leads_unsubscribe_lang', value);
    },

    getLang() {
        return localStorage.getItem('ez_leads_unsubscribe_lang');
    }
};