import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].mailing;

class MailService extends IService {
    constructor(http) {
        super(http);
    }

    async info(token, source) {
        try {
            const params = {source: source};
            const response = await this.http.get(`${server_base}/api/mail/subscriptions/${token}`, params);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async unsubscribe(token, source, tracking) {
        try {
            const params = {
                token: token,
                source_t: tracking,
                source: source,
            }
            const response = await this.http.put(`${server_base}/api/mail/subscriptions`, params);
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default MailService;