import HttpClient from "../helpers/http.client";

class IService {
    constructor(http) {
        this.http = http;
        if (!http)
            this.http = new HttpClient();
    }
}

export default IService;