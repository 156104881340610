import Vue from 'vue';
import App from './App.vue';
import config from './config';
import {i18n} from '@/plugins/i18n';
import router from '@/router';

import '@/helpers/import.ui.components';

const env = config.stage;

Vue.config.productionTip = config[env] !== "development";

Vue.prototype.$ws_server = function () {
    return config[env].ws_server;
};

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#ez_leads_unsubscribe');