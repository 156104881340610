import {i18n} from '@/plugins/i18n';
import LocalStore from '@/helpers/LocalStore';
import MailService from "@/services/mail.service";

const service = new MailService();

export default {
    name: "Unsubscribe",

    data() {
        return {
            info: null,
            token: '',
            tracking: '',
            source: '',
            status: {
                loading: false,
                unsubscribed: false,
            }
        }
    },

    async mounted() {
        this.setTokens();
        await this.getInfo();

        if (this.info) {
            this.setLanguage(this.info.Customer.Lang);
        }
    },

    computed: {
        companyLogo() {
            return `${this.$ws_server()}/api/files/download/${this.info.Company.Logo}`;
        },
    },

    methods: {
        setTokens() {
            this.token = this.$route.query.token;
            this.tracking = this.$route.query.source_t;
            this.source = this.$route.query.source;
        },

        async getInfo() {
            const res = await service.info(this.token, this.source);
            if (res && !res.error) {
                this.info = res.data.info;
                this.status.unsubscribed = !this.info.Customer.EmailConsent.Campaigns;
            }
        },

        async unsubscribe() {
            this.status.loading = true;

            const res = await service.unsubscribe(this.token, this.source, this.tracking);
            if (res && !res.error) {
                this.status.unsubscribed = true;
                this.status.loading = false;
            }
        },

        setLanguage(language) {
            const local_lang = LocalStore.getLang();
            const customer_lang = language === 'SPANISH' ? 'es' : 'en';

            if (!local_lang) {
                LocalStore.setLang(customer_lang);

                if (i18n.locale !== customer_lang) {
                    i18n.locale = customer_lang;
                }
            }
        }
    }
};