var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{class:{
                  'btn-primary': _vm.type === 'primary',
                  'btn-secondary': _vm.type === 'secondary',
                  'btn-danger': _vm.type === 'danger',
                  'btn-delete': _vm.type === 'delete',
                  'btn-custom': _vm.type === 'custom',
                  'btn-edit': _vm.type === 'edit',
                  'btn-cancel': _vm.type === 'cancel',
                  'btn-small': _vm.size === 's',
                  'btn-middle': _vm.size === 'm',
                  'btn-large': _vm.size === 'l',
                  'btn-extra': _vm.size === 'xl'
                },style:({
                  '--fsize': _vm.fsize,
                  '--width': _vm.width,
                  '--height': _vm.height,
                  '--bg': _vm.bg,
                  '--bg_hover': _vm.bg_hover,
                  '--border': _vm.border,
                  '--bradius': _vm.bradius,
                  '--color': _vm.color,
                  '--justify': _vm.justify,
                  '--padding': _vm.padding,
                  '--icon_margin': _vm.icon_margin,
                  '--fweight': _vm.fweight
                }),attrs:{"type":"button","disabled":_vm.disabled || _vm.isLoading},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.iconType === 'fa' && _vm.iconLeft && _vm.iconLeft.length > 0)?_c('i',[_c('font-awesome-icon',{attrs:{"icon":[_vm.iconType, _vm.iconLeft]}})],1):_vm._e(),(_vm.iconType === 'fal' && _vm.iconLeft && _vm.iconLeft.length > 0)?_c('i',[_c('font-awesome-icon',{attrs:{"icon":[_vm.iconType, _vm.iconLeft]}})],1):(_vm.iconType === 'url' && _vm.iconLeft && _vm.iconLeft.length > 0)?_c('i',[_c('img',{attrs:{"src":_vm.iconLeft,"alt":""}})]):_vm._e(),(_vm.iconType === 'ez' && _vm.iconLeft && _vm.iconLeft.length > 0)?_c('i',{class:_vm.iconLeft}):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.iconType === 'fa' && _vm.iconRight && _vm.iconRight.length > 0)?_c('i',[_c('fa',{attrs:{"icon":_vm.iconRight}})],1):(_vm.iconType === 'url' && _vm.iconRight && _vm.iconRight.length > 0)?_c('i',[_c('img',{attrs:{"src":_vm.iconRight,"alt":""}})]):(_vm.iconType === 'ez' && _vm.iconRight && _vm.iconRight.length > 0)?_c('i',{class:_vm.iconRight}):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"spinner"},[_c('Spinner')],1):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }