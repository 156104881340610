import Vue from 'vue';
import Router from 'vue-router';

import Unsubscribe from "@/components/Unsubscribe/index.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'Unsubscribe',
            component: Unsubscribe,
            path: '/'
        },
    ]
});

export default router;