<template>
  <div id="app">
    <Unsubscribe/>
  </div>
</template>

<script>
import Unsubscribe from './components/Unsubscribe/index.vue';

export default {
  name: 'app',
  components: {
    Unsubscribe
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>