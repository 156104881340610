export default {
    stage: process.env.NODE_ENV || 'development',
    production: {
        ws_server: "https://api.ezlead.io",
        mailing: "https://mailing.ezlead.io"
    },
    development: {
        ws_server: "http://localhost:5000",
        mailing: "http://localhost:4012"
    },
    staging: {
        ws_server: "https://api.ezleadsdev.com",
        mailing: "https://mailing.ezleadsdev.com",
    }
};
